.blog-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.blog-card {
  width: calc(33.33% - 40px); 
  min-width: 300px;/* 33.33% width for each card with 20px margin on both sides */
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
}

.blog-card-image {
  width: 100%;
  height: 300px; /* You can adjust the image height as needed */
  object-fit: cover;
}

.blog-card-details {
  padding: 20px;
}

.blog-card-type {
  font-size: 14px;
  color: #666;
}

.blog-card-title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.blog-card-date {
  font-size: 14px;
  color: #888;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .blog-card {
    width: calc(50% - 40px);
  }
}

@media (max-width: 480px) {
  .blog-card {
    width: calc(100% - 40px);
  }
}
