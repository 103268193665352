@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

.nav-box {
  background-color: rgb(255, 255, 255);
  border-radius: 10000px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.08);
  border-style: solid;
  position: relative;
  padding-right: 14px;
  padding-left: 30px;
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);
}

.nav-box-wrap {
  width: 100%;
  position: fixed;
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 48px;
  font-family: "Link Sans", Arial, sans-serif;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-left {
  display: flex;
  align-items: center;
}

.list-left > div {
  white-space: nowrap;
  font-size: 15px;
  line-height: 1.5;
  color: rgb(103, 107, 95);
  padding: 11px 16px;
  border-radius: 8px;
  text-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px;
}

.list-left > div:hover {
  background-color: rgb(239, 240, 236);
  cursor: pointer;
}

.login-text {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.5;
  color: rgb(30, 35, 48);
}

.list-right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.login-btn {
  position: relative;
  z-index: 1;
  background-color: rgb(239, 240, 236);
  border-radius: 8px;
  padding: 18px 26px 20px;
}

.signup-btn {
  position: relative;
  z-index: 1;
  background-color: rgb(30, 35, 48);
  border-radius: 10000px;
  padding: 18px 30px 20px;
}

.signup-text {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.5;
  color: rgb(255, 255, 255);
}

/********* HERO 1 ***********/

.home-page .hero1 {
  background-color: rgb(233, 192, 233);
  height: 100vh;
  display: flex;
  align-items: center;
  padding-left: 46px;
  gap: 5%;
}

.hero-heading {
  font-family: "Link Sans", "Arial Black", Arial, sans-serif;
  font-size: clamp(32px, 8.5vmin, 88px);
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 1.05;
  text-align: left;

  color: rgb(80, 34, 116);
}

.hero-text {
  font-family: "Link Sans", Arial, sans-serif;
  margin-top: 24px;
  font-weight: 300;
  letter-spacing: -0.02em;
  line-height: 1.5;
  margin-bottom: 16px;
  color: rgb(80, 34, 116);
}

.mt-48 {
  margin-top: 48px;
}

.hero-form-wrap {
  display: flex;
  justify-content: flex-start;
}

.hero-form {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
}

.hero-input {
  background: rgb(255, 255, 255);
  display: flex;
  font-weight: 400;
  width: 245px;
  position: relative;
}

.hero-input-wrap {
  padding: 19px 16px;
  border-radius: 8px;
}

.hero-input p {
  color: rgb(103, 107, 95);
}

.cpEprS {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.5;
}

.input-text ~ .outline {
  content: "";
  display: block;
  position: absolute;

  border: 2px solid white;
  border-radius: 12px;
  pointer-events: none;
}

.input-text:focus ~ .outline {
  visibility: visible;
  inset: -4px;
  transition: all 0.1s cubic-bezier(0.33, 1.69, 0.87, 1.07) 0s;
  border: 10px;
}

.input-text {
  border: 0px;
  outline: 0px;
  padding: 0px;
  margin: 0px 8px 0px 0px;
  overflow-x: hidden;
  width: 100%;
  font-weight: 400;
  font-size: 17px;
  color: rgb(103, 107, 95);
  padding-left: 1px;
}

.hero-form-wrap {
  display: flex;
  box-sizing: border-box;
  min-width: 0px;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.claim-btn {
  padding: 0px;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.claim-btn-inner {
  position: relative;
  z-index: 1;
  background-color: rgb(233, 192, 233);
  border-radius: 10000px;
  min-width: 0px;
  padding: 18px 26px 20px;
}

.claim-btn-inner::before {
  content: "";
  display: block;
  position: absolute;
  inset: 6px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 10000px;
  z-index: -2;
  transition: none 0s ease 0s;
  visibility: hidden;
}

.claim-btn-text {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.5;
  color: rgb(30, 35, 48);
}

.hero1-bg {
  max-width: 40vw;
  object-fit: contain;
  transform: translateY(8%);
}

.hero1-cg {
  max-width: 45vw;
  object-fit: contain;
  transform: translateY(8%);
}

.home-page .hero2 {
  background-color: rgb(233, 192, 233);
  height: 120vh;
  display: flex;

  align-items: center;
  padding-left: 66px;
  gap: 5%;
}

.hero-heading-2 {
  font-family: "Link Sans", "Arial Black", Arial, sans-serif;
  font-size: clamp(28px, 8vmin, 56px);
  font-weight: 820;
  margin-top: 2;
  letter-spacing: -0.02em;
  line-height: 1.05;
  text-align: left;
  color: rgb(80, 34, 116);
}

.hero-text-2 {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: clamp(18px, 2vmin, 20px);
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  color: rgb(80, 34, 116);
  margin-top: 24px;
}

.started-btn-inner {
  position: relative;
  z-index: 1;
  background-color: rgb(80, 34, 116);
  border-radius: 10000px;
  padding: 18px 29px 20px;
}

.started-btn {
  padding: 0px;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.started-btn-inner::before {
  content: "";
  display: block;
  position: absolute;
  inset: 6px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 10000px;
  z-index: -2;
  transition: none 0s ease 0s;
  visibility: hidden;
}

.started-btn-text {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 17.2px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.5;
  color: rgb(255, 255, 255);
}

.home-page .hero3 {
  background-color: rgb(120, 0, 22);
  height: 100vh;
  display: flex;
  align-items: center;
  padding-left: 30px;
  gap: 5%;
}

.hero-heading-3 {
  font-family: "Link Sans", "Arial Black", Arial, sans-serif;
  font-size: clamp(28px, 8vmin, 56px);
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 1.05;
  text-align: left;
  color: rgb(233, 192, 233);
}

.hero-text-3 {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: clamp(18px, 2vmin, 20px);
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  margin-bottom: 16px;
  color: rgb(233, 192, 233);
  margin-top: 24px;
}

.home-page .hero4 {
  background-color: rgb(243, 243, 241);
  height: 100vh;
  display: flex;
  align-items: center;
  padding-left: 30px;
  gap: 3.5%;
}

.hero4-bg {
  max-width: 32.8vw;
  object-fit: contain;
  transform: translateY(8%);
}

.hero-heading-4 {
  font-family: "Link Sans", "Arial Black", Arial, sans-serif;
  font-size: clamp(28px, 8vmin, 56px);
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 1.05;
  text-align: left;
  color: rgb(30, 35, 48);
}
.hero-text-4 {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: clamp(18px, 2vmin, 20px);
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  color: rgb(30, 35, 48);
  margin-top: 24px;
}

.footer {
  background-color: rgb(80, 34, 116);
  min-height: 200vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer h1 {
  font-family: "Link Sans", "Arial Black", Arial, sans-serif;
  font-size: 52px;
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 1.05;
  text-align: center;
  color: rgb(233, 192, 233);
}

.footer-form {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.links-wrap {
  min-width: 0px;
  padding: 64px;
  margin-top: 40px;
  background-color: rgb(255, 255, 255);
  border-radius: 16px;
  position: relative;
  width: 93.2%;
}

.bTRgKr {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.wrNff {
  min-width: 0px;
  flex: 1 1 0%;
}

.kLEIyT {
  min-width: 0px;
  margin-bottom: 24px;
}

.dubjts {
  font-size: 22px;
  font-family: "Link Sans", Arial, sans-serif;
  font-weight: 500 !important;
  letter-spacing: -0.01em;
  line-height: 1.3;
  color: rgb(30, 35, 48);
}

.gQHsyf {
  text-decoration: none;
  display: block;
  margin: 0px 0px 16px;
  cursor: pointer;
}

.gQHsyf p {
  color: rgb(103, 107, 95);
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 13px;
  font-weight: 418;
  letter-spacing: 0.01em;
  line-height: 1.5;
}

.kNXMgw {
  display: flex;
  box-sizing: border-box;
  min-width: 0px;
  gap: 8px;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
}

.hMmGGJ svg {
  fill: rgb(30, 35, 48);
  width: 64px;
  height: 64px;
}

.kcskEf {
  border-radius: 60px;
  margin-top: 16px;
  background-color: rgb(233, 192, 233);
  position: relative;
  width: 93.2%;
  padding-top: 188px;
  padding-bottom: 188px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iewPcV {
  color: rgb(80, 34, 116);
  width: 60%;
}

.ilowQg svg {
  max-width: 100%;
  fill: currentcolor;
}

.plans-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  background-color: rgb(243, 243, 241);
  padding-bottom: 128px;
}

.plans-text {
  font-family: "Link Sans", "Arial Black", Arial, sans-serif;
  font-size: clamp(27px, 8vmin, 56px);
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 1.05;
  text-align: center;
  color: rgb(30, 35, 48);
  width: 94.2%;
}

.plans-btn {
  position: relative;
  z-index: 1;
  background-color: rgb(233, 192, 233);
  border-radius: 10000px;
  min-width: 0px;
  padding: 18px 26px 20px;
}

.plans-btn::before {
  content: "";
  display: block;
  position: absolute;
  inset: 6px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 10000px;
  z-index: -2;
  transition: none 0s ease 0s;
  visibility: hidden;
}

.plans-btn-text {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.5;
  color: rgb(30, 35, 48);
}

.card {
  min-width: 0px;
  background-color: rgb(255, 255, 255);
  height: 100%;
  border-radius: 32px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.01);
  width: 31.8%;
  height: 475px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card-img {
  object-fit: contain;
}

.card-text {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: clamp(18px, 2vmin, 20px);
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  color: rgb(30, 35, 48);
  text-align: center;
  width: 80%;
}

.login-wrap {
  display: flex;
  height: 100vh;

  font-family: "Inter", "Helvetica", "Arial", "system-ui", "-apple-system" !important;
  position: relative;
}

.login-main {
  padding: 3rem;
  display: flex;
  justify-content: center;
  width: 66.5%;
}

.logo-wrap {
  height: 1.5rem;
  position: absolute;
  z-index: 50;
  left: 1rem;
  top: -1rem;
}

.login-form-wrap {
  width: 600px;
  margin: auto;
  flex-direction: column;
}

.bg-chalk {
  background-color: rgb(246 247 245/1);
  width: 33.5%;
  background: url(../public/download.png);
  background-size: cover;
  background-position: center;
}

.login-heading {
  font-size: 48px;
  line-height: 1.2;
  margin-bottom: 3rem;
  letter-spacing: -2px;
  font-weight: 900 !important;
}

.field {
  overflow: hidden;
  width: 100%;
  background-color: #f6f7f5;
  height: 50px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  padding-left: 18px;
  color: rgb(103 107 95/1);
  margin-bottom: 0.5rem;
  position: relative;
  border: 2px solid transparent;
}

.field:hover {
  border: 2px solid #ccc;
}

.field input {
  color: rgb(103 107 95/1);
  border: none;
  outline: none;
  background: transparent;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  line-height: 48px;
  transition-duration: 75ms;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
}

.field:focus-within {
  border: 2px solid #000;
}

/* Basic button styles */
.login-page-btn {
  position: relative;
  transition: all 0.75s ease-out;
  width: 100%;
  height: 3rem;
  border-radius: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: rgb(242, 101, 124);
  color: #fff;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.32px;
  margin-top: 50px;
}

/* .login-btn text styles */
.login-page-btn span {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .login-page-btn text block styles */
.login-page-btn span span {
  display: block;
  font-weight: 600;
  font-size: 1rem;
  /* You may adjust the font size as needed */
}

.forgot {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(129 41 217/1);
  padding-top: 2rem;
}

.under {
  text-decoration: underline;
  color: rgb(129 41 217/1);
}

.recap {
  height: 15vh;
  display: flex;
  align-items: end;
  justify-content: center;
  font-family: "Link Sans", Arial, sans-serif;
  white-space: nowrap;
  color: rgb(103 107 95/1);
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
}

.dd {
  font-family: "Link Sans", Arial, sans-serif;
  color: rgb(103 107 95/1);
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
}

.bg-chalk-2 {
  background-color: rgb(246 247 245/1);
  width: 33.5%;
  background: url(../public/download2.png);
  background-size: cover;
  background-position: center;
}

.recap a {
  text-decoration: underline;
  margin: 0 3px;
}

.signup-tt {
  margin-bottom: 3rem;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: -0.32px;
  color: rgb(103 107 95/1);
  margin-top: -2rem;
  transform: translateX(4px);
}

.text-concrete {
  color: rgb(103 107 95/1);
}

.text-sm {
  font-size: 14px;
  line-height: 1.5;
}

.font-semibold {
  font-weight: 600;
}

.home-page .pricing1 {
  background-color: rgb(233, 192, 233);
  height: 120vh;
  display: flex;
  flex-direction: column;
  padding-top: 32vh;
  align-items: center;
  padding-left: 0px;
  gap: 5%;
}

.pricing-bar {
  min-width: 93.6%;
  display: grid;
  grid-template-columns: 1fr auto;
  text-align: left;
}

.pricing-bar .pick-plan-heading {
  font-family: "Link Sans", "Arial Black", Arial, sans-serif;
  color: rgb(80, 34, 116);
  text-align: left;
  font-size: 97px;
  font-weight: 800;
  line-height: 1.05;
  letter-spacing: -0.08em;
  width: 100%;
  margin: 0px;
  display: inline-block;
}

.pricing-switch {
  display: flex;
  align-items: flex-end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
}

.pricing-switch-wrap {
  align-items: flex-end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  min-width: 250px;
  display: flex;
  flex-direction: column;
}

.pricing-switch-txt {
  margin-bottom: 1.5rem;
  text-align: center;
  color: rgb(30, 35, 48);
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 14px;
}

.pricing-switch-options {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  height: 56px;
  -webkit-box-pack: center;
  justify-content: center;
  max-width: 186px;
  padding: 0.25rem;
  width: 100%;
  border-radius: 28px;
  background: rgb(255, 255, 255);
}

.pricing-option-slct {
  font-size: 13px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  height: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  background: rgb(30, 35, 48);
  color: rgb(255, 255, 255);
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 13px;
  border-radius: 24px;
  cursor: pointer;
}

.pricing-option-unslct {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  height: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  background: transparent;
  color: rgb(30, 35, 48);
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 13px;
  border-radius: 24px;
  cursor: pointer;
}

.pricing-cards {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
  margin-top: 2rem;
  width: 93.7%;
}

.pricing-cards {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
  margin-top: 2rem;
  width: 93%;
}

.pricing-cards.two {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
  margin-top: 2rem;
  width: 93.7%;
}

.pricing-cards > div {
  margin-bottom: 1rem;
  box-shadow: 19px 16px 20px 2px rgb(0 0 0 / 5%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.pricing-card-wrap {
  width: 100%;
}

.pricing-card-header {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 1.5rem;
  position: relative;
  background: rgb(80, 34, 116);
}

.pricing-tag-wrap {
  display: flex;
  flex-wrap: wrap-reverse;
  -webkit-box-pack: justify;
  justify-content: space-between;
  gap: 0.5rem;
}

.pricing-text-top > ul {
  list-style: circle;
}

.pricing-text-top > ul > li {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
}

.kZkFqo {
  min-width: 0px;
}

.pricing-card-header .pick-plan-heading {
  color: rgb(255, 255, 255);
  margin-right: 0.5rem;
  text-align: left;
  font-size: 28px;
  font-weight: 560;
  line-height: 1.3;
  font-family: "Link Sans", Arial, sans-serif;
}

.pricing-tag-value {
  display: flex;
  margin-top: 0.5rem;
  align-items: flex-end;
  font-family: "Link Sans", Arial, sans-serif;
}

.pricing-tag-currency {
  margin-bottom: 0.3125rem;
  color: rgb(239, 240, 236);
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 14px;
}

.pricing-card-footer {
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  background: rgb(255, 255, 255);
}

.pricing-card-text {
  padding: 24px 26px 24px;
}

.pricing-text-top {
  font-size: 17px;
  line-height: 26.4px;
  padding: 0px;
  min-height: 104px;
  text-align: center;
  color: rgb(30, 35, 48);
  font-family: "Link Sans", Arial, sans-serif;
}

.pricing-btn-wrap {
  -webkit-box-align: center;
  align-items: center;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.pricing-btn-inner {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 18px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
  line-height: 165%;
  font-weight: 408;
  color: rgb(80, 34, 116);
  padding: 18px 0px;
  width: auto;
  border-radius: 32px;
  border: 1px solid rgb(182, 159, 175);
  background: transparent;
}

.pricing-btn-wrap a {
  width: 100%;
}

.tag-wrapper {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background: rgb(210, 232, 35);
  color: rgb(30, 35, 48);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  visibility: visible;
  border-radius: 9.33333px;
  font-size: 13px;
  width: 70px;
  font-family: "Link Sans", Arial, sans-serif;
  margin-bottom: 0.8rem;
}

.jscLyN {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 1.5rem;
}

.isHaNx {
  margin-left: 0.75rem;
  color: rgb(112, 118, 77);
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 13px;
}

.home-page .users1 {
  background-color: rgb(243, 243, 241);
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  padding-top: 32vh;
  align-items: center;
  padding-left: 0px;
  gap: 5%;
}

.user-heading {
  font-family: "Link Sans", "Arial Black", Arial, sans-serif;
  font-size: clamp(48px, 8.9vmin, 88px);
  font-weight: 800;
  letter-spacing: -0.05em;
  line-height: 1.02;
  text-align: center;
  color: rgb(30, 35, 48);
}

.user-text {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 16px;
  font-weight: 418;
  letter-spacing: 0.01em;
  line-height: 1.5;
  text-align: center;
  color: rgb(103, 107, 95);
  margin-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
}

.filter-hd h3 {
  display: block;
  min-width: 0px;
  margin-top: 14px;
  margin-bottom: 48px;
  font-size: 24px;
  font-family: "Link Sans", Arial, sans-serif;
  font-weight: 600 !important;
  letter-spacing: -0.01em;
  line-height: 1.3;
  color: rgb(30, 35, 48);
}

.filter-links {
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.filter-btns {
  border: 1px solid rgb(168, 170, 162);
  border-radius: 1000px;
  padding: 13px 16px;
  margin: 0px 8px 8px 0px;
  color: rgb(27, 29, 26);
  cursor: pointer;
}

.filter-btns:hover {
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
}

.filter {
  width: 30%;
}

.results {
  width: 70%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.user-card {
  margin-bottom: 2rem;
  width: 32%;
  height: 635px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8%;
  transition: all 0.3 ease;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid rgb(224, 226, 217);
  background: #7c9dca;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #7c9dca, #d694a6);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.user-avatar {
  width: 80px;
  /* Set the desired width of the circular image container */
  height: 80px;
  /* Set the desired height of the circular image container */
  border-radius: 50%;
  /* This creates the circular shape */
  overflow: hidden;
  /* Hide any overflowing content (like corners of the image) */
}

.user-avatar img {
  width: 100%;
  /* Make the image inside the container take up the full width */
  height: 100%;
  /* Make the image inside the container take up the full height */
  object-fit: cover;
  /* Maintain the aspect ratio and cover the container */
}

.user-name {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 1.5;
  font-family: "Link Sans", Arial, sans-serif;
  color: #fff;
}

.user-desc {
  margin-top: 10px;
  font-size: 11px !important;
  text-align: center;
  margin-bottom: 0.3125rem;
  color: rgb(239, 240, 236);
  opacity: 0.8;
  line-height: 1.4;
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 14px;
}

.user-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  margin-top: 2rem;
}

.user-buttons a {
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 40px;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.4;
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.user-buttons a:hover {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}

.contact-text {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: clamp(16px, 2vmin, 20px);
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.5;
  margin-bottom: 16px;
  color: rgb(30, 35, 48);
  padding-top: 2rem;
}

.contact-form-wrapper {
  font-family: "Link Sans", Arial, sans-serif;
  padding: 100px 0;
}

.contact-form {
  padding: 30px 40px;
  background-color: #ffffff;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  width: 600px;
  height: 700px;
}

.contact-form textarea {
  resize: none;
}

.contact-form .form-text-area {
  font-family: "Link Sans", Arial, sans-serif;
  overflow: hidden;
  width: 100%;
  background-color: #f6f7f5;
  height: 200px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  padding: 18px;
  color: rgb(103 107 95/1);
  margin-bottom: 0.5rem;
  position: relative;
  border: 2px solid transparent;
}

.contact-form .title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.contact-form .description {
  color: #aeb4b9;
  font-size: 14px;
  text-align: center;
  margin-bottom: 2rem;
}

.contact-btn {
  padding: 0px;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 40px;
}

.contact-btn-inner {
  position: relative;
  z-index: 1;
  background-color: rgb(233, 192, 233);
  border-radius: 10000px;
  min-width: 0px;
  color: #fff;
  padding: 12px 96px 12px;
}

.contact-btn-inner::before {
  content: "";
  display: block;
  position: absolute;
  inset: 6px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 10000px;
  z-index: -2;
  transition: none 0s ease 0s;
  visibility: hidden;
}

.contact-btn-text {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 1.5;
  color: rgb(30, 35, 48);
}

.admin-panel {
  background-color: #f3f3f1;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

.hover-navitem:hover {
  background-color: #e6e6e6;
}

.hover-navitem-selected:hover {
  background-color: #eeeeee;
}

.bg-gr {
  background-color: rgb(233, 233, 233);
}

.bg-gg:hover {
  background-color: rgb(246 247 245 / 1);
}

.try-btn {
  display: flex;
  width: 150px;
}

.avt {
  width: 2.5rem;
  height: 2.5rem;
  background-color: rgb(96, 105, 108);
  color: rgb(255, 255, 255);
  border-radius: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 9999px;
}

.avt {
  width: 2.5rem;
  height: 2.5rem;
  background-color: rgb(96, 105, 108);
  color: rgb(255, 255, 255);
  border-radius: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 9999px;
  cursor: pointer !important;
}

.avt.bv {
  height: 48px;
  width: 48px;
}

.bg-sand {
  background-color: rgb(224 226 217 / 1);
}

.w-full {
  width: 100%;
}

.my-7 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.setting-link {
  -webkit-box-align: center;
  align-items: center;
  height: 35px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  padding: 8px 10px;
  width: 100%;
  min-height: 48px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 200px;
}

.setting-link span {
  padding-left: 10px;
  font-size: 14px;
  white-space: nowrap;
  letter-spacing: normal;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.setting-link:hover {
  background-color: rgb(235, 238, 241);
}

.side-nav {
  padding: 28px 60px 56px;
  position: fixed;
}

.dTSpKS {
  width: 24px;
  height: 24px;
}

.settings-feed {
  margin-left: 20vw;
  padding-top: 22px;
  min-width: 35vw;
}

.zAeKe {
  background-color: rgb(255, 255, 255);
  border-top: 0px;
  border-left: 0px;
  padding: 20px;
  border-radius: 8px;

  width: 95%;
  position: relative;
}

.hYulVh {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.text-concrete {
  color: rgb(103 107 95 /1);
}

.text-md {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.32px;
}

.kUMKOy {
  margin-top: 32px;
  font-size: 14px;
  white-space: nowrap;
}

.text-white {
  color: rgb(255 255 255 / 1);
}

.bg-primary {
  background-color: rgb(129 41 217 / 1);
}

.px-md {
  padding-left: 1.3rem;
  padding-right: 1.3rem;
}

.cQriIP {
  flex: 0 0 32px;
  height: 20px !important;
  width: 32px !important;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  margin-bottom: 0px;
  margin-left: auto;
  left: 1px;
  transform: translateY(-75px);
  background-color: rgb(187, 187, 187);
  transition: background-color 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
  display: block !important;
}

.cQriIP::before {
  transition: left 0.2s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: rgb(255, 255, 255);
  top: 1px;
  left: 1px;
  position: absolute;
}

.cQriIP .sc-jUEnpm {
  opacity: 0;
  position: absolute;
  inset: 0px;
  width: 32px;
  height: 20px;
  cursor: pointer;
}

.cQriIP.checked {
  background-color: rgb(57, 224, 155);
}

.cQriIP.checked::before {
  left: 13px;
}

.profile-feed {
  padding-top: 22px;
  min-width: 42vw;
}

.profile-feed h2 {
  font-size: 32px;
  margin-top: 64px;
  text-align: center;
  width: 100%;
}

label.active-input .fields {
  background: rgba(0, 0, 0, 0);
  font-weight: 400;
  border: 0;
  color: #282f37;
  outline: none;
  cursor: text;
  display: block;
  width: 100%;
  line-height: 32px;
  padding-bottom: 3px;
  transition: opacity 0.2s ease-in-out;
  border-bottom: 1px solid #d7dce1;
}

.billing-name label.active-input {
  margin-top: 8px;
}

label.active-input {
  height: 35px;
  position: relative;
  color: #686d73;
  display: block;
  margin-bottom: 0;
}

.active-span {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  font-weight: 500;
  height: 40px;
  line-height: 32px;
  font-size: 12px;
  color: #686d73;

  transition: border-bottom-color 0.2s ease-in-out;
  cursor: text;
  pointer-events: none;
}

.submit2 {
  margin-top: 16px;
}

.button.purple {
  background-color: #7c41ff;
  color: #fff;
}

.button.large {
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px !important;
  border-radius: 12px;
}

.gyOhIp {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: rgb(83, 88, 95);
}

.img-circle {
  top: 10px;
  left: 10px;
  width: 44px;
  height: 44px;
  opacity: 1;
  visibility: visible;
}

.tree__sidebar {
  width: 64px;
}

.jBEgpy {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  border-radius: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.dRQKnM {
  height: 44px;
  width: 44px;
}

.bpcatB {
  font-weight: 600;
  font-size: 18px;
  font-family: Inter;
}

.trees .tree__section:nth-of-type(2) {
  border-top-right-radius: 3px;
}

.tree__section {
  padding: 10px 10px 10px 20px;
  line-height: 38px;
}

.tree__section ul li {
  font-size: 13px;
}

.tree .username .name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0.7;
}

.tree__section {
  position: relative;
  padding: 12px;
  border-bottom: 1px solid #f5f6f8;
}

.tree__section--plan h5,
.tree__section--plan .name {
  padding: 0;
  line-height: 32px !important;
}

.tree__section--admins ul li.user {
  padding: 5px 8px 8px;
  background-color: #00000005;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 3px;
}

.tree__section--admins ul li span.tag {
  display: inline-flex;
  align-items: center;
  margin-left: 15px;
  font-weight: 700;
  padding: 1px 4px;
  font-size: 11px;
  opacity: 1;
  color: #0a0b0d;
  background-color: #d7dce1;
  border-radius: 3px;
  height: 20px;
}

.tree__section--admins ul li {
  font-size: 11px;
  color: #686d73;
}

.tree__section--footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px !important;
}

.tree__section--footer .button {
  position: relative;
  text-align: center;
  outline: 0 !important;
  background-color: #3d444b;
  color: #fff;
  padding: 9px 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
}

.tree__section--footer .button.outline {
  background-color: #0000;
  border: 2px solid #878e94;

  color: #3d444b;
  padding: 7px 12px;
}

.text-header-color {
  color: hsl(0, 0%, 100%);
}

.pb-9 {
  padding-bottom: 36px;
}

.bg-center {
  background-position: 50%;
}

.bg-cover {
  background-size: cover;
}

.bg-header-image {
  background-image: url(https://downloads.intercomcdn.com/i/o/411923/d7d17be9b7447460074a7740/d81eb104a102eaba2b4ab969ea04ac8e.png);
}

.bg-header-bg {
  background-color: rgb(80, 34, 116);
}

.mb-9 {
  margin-bottom: 36px;
}

.relative {
  position: relative;
}

.justify-center {
  justify-content: center;
}

.flex {
  display: flex;
}

.w-240 {
  width: 960px;
}

.items-center {
  align-items: center;
}

.mo__body {
  flex: 1;
  min-width: 0;
  position: relative;
}

.header__logo {
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
  z-index: 2;
}

.header__logo a {
  display: inline-block;
  text-decoration: none;
}

.header__logo img {
  max-width: 360px;
  max-height: 35px;
  vertical-align: baseline;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
}

.text-welcome-message {
  font-size: 28px;
}

.fill-header-color {
  fill: #fff;
}

.inin {
  width: 100%;
  border-radius: 10px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.08);
  background-color: hsla(0, 0%, 100%, 0.2) !important;
  padding: 16px;
  padding-left: 48px;
  font-size: 18px;
  color: var(--header-color);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
}

.gap-4 {
  gap: 16px;
}

.bg-collection-card-bg {
  background-color: rgb(233, 192, 233);
}

.w-6 {
  width: 24px;
}

.h-6 {
  height: 24px;
}

.text-body-primary-color {
  color: #000;
}

.shadow-card {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 470px;
  margin-top: 20px;
}

.help-footer {
  margin-top: 104px;
  padding: 48px 0;
  text-align: left;
  font-size: 14px;
  flex-shrink: 0;
  background-color: rgb(37, 79, 26);

  color: hsl(211, 10%, 61%);
}

.help-footer .content {
  max-width: 100%;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.footer__logo {
  height: 50px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  color: hsl(211, 10%, 61%);
}

.footer__logo img {
  max-height: 32px;
  filter: grayscale(100%) contrast(80%);
  display: inline;
}

.cFMxiM {
  font-family: "Link Sans", "Arial Black", Arial, sans-serif;
  font-size: clamp(32px, 8.5vmin, 88px);
  font-weight: 820;
  letter-spacing: -0.02em;
  line-height: 1.05;
  color: rgb(30, 35, 48);
  padding-bottom: 128px;
}

.terms-page {
  padding-top: 200px;
  padding-left: 56px;
  padding-right: 56px;
}

.gGDurp p {
  margin-bottom: 32px;
}

.gGDurp ul:not(.unstyled-list) {
  list-style-type: disc;
  padding-left: 2em;
}

.gGDurp ul {
  margin-top: 32px;
  margin-bottom: 32px;
}

.gGDurp ul li {
  padding-left: 1em;
  margin-bottom: 1em;
}

.gGDurp ul:not(.unstyled-list) li::marker {
  color: rgb(255, 108, 2);
}

.gGDurp h5 {
  font-family: "Link Sans", "Arial Black", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.5;
  color: rgb(30, 35, 48);
  margin-bottom: 32px;
}

.MIUTU {
  display: block;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.01em;
  margin: 0px;
  padding: 0px;
  margin-top: 80px;
  padding-left: 66px;
}

.jMYFMD {
  grid-template-columns: 1fr 1fr;
  row-gap: 0px;
  padding: 48px 0px 64px;
  gap: 40px 23px;
  display: grid;
  width: 90%;
  margin: auto;
}

.jMYFMD > div {
  min-height: 268px;
  max-width: 756px;
  border-radius: 48px;
  overflow: hidden;
}

.jMYFMD .img {
  object-fit: cover;
  max-width: 100%;
  height: 268px;
  border-radius: 48px;
}

.foLnLr {
  font-size: 20px;
  color: rgb(38, 101, 214);
  line-height: 165%;
  -webkit-box-align: center;
  align-items: center;
  text-decoration: underline;
  padding-right: 66px;
  transform: translateY(40px);
}

.itijnk h3 {
  font-size: 24px;
  margin-bottom: 32px;
  color: rgb(30, 35, 48);
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.3;
}

.itijnk p {
  margin-bottom: 32px;
}

.analytic-count-wrap {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(216, 216, 216);
  margin-bottom: 24px;
  max-width: 97vw;
  margin: auto;
  border-radius: 20px;
  padding: 33px 0;
}

.analytic-count-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.analytic-grid {
  display: flex;
}

.analytic-box {
  width: 136px;
  margin-top: 30px;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  text-align: center;
}

.analytic-title {
  display: flex;
  flex-direction: row;
  -webkit-box-align: baseline;
  align-items: baseline;
  -webkit-box-pack: center;
  justify-content: center;
  height: auto;
  margin-bottom: 4px;
}

.analytic-count {
  line-height: 100%;
  font-weight: 300 !important;
}

.esbyIi {
  border-radius: 100%;
  height: 8px;
  width: 8px;
  margin-right: 6px;
}

.date-pick-wrap {
  display: flex;
  height: 48px;
  position: relative;
  margin: 0px 16px 4px;

  width: 100%;
}

.date-pick-svg {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 14px;
  fill: rgb(172, 181, 191);
  z-index: 1;
  width: 20px;
  height: 20px;
}

.date-input {
  flex: 1 1 0%;
  padding-left: 52px;
  padding-right: 8px;
  color: rgb(10, 11, 13);
  font-size: 14px;
  font-weight: 600;
  border: 1px solid rgb(215, 220, 225);
  border-radius: 8px;
  cursor: pointer;
}

.gndtOw.empty {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  min-height: 245px;
  padding: 0px 8px;
  background-color: rgb(255, 255, 255);
  margin-top: 20px;
  border-radius: 8px;
  width: 100%;
}

.no-activity {
  width: 100%;
  max-width: 256px;
  padding: 32px 0px;
}

.no-activity-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.no-activity-img {
  margin: 0px auto 16px;
  width: 80px;
  height: 80px;
  display: block;
  object-fit: contain;
  object-position: initial;
  filter: none;
}

.no-activity-txt {
  margin: 0px 0px 4px;
  font-weight: 600;
  color: rgb(10, 11, 13);
}

.fFGAIg {
  display: flex;
}

.performance-txt {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  height: 100%;
}

.performance-txt:not(:last-child) {
  border-bottom: 1px solid rgb(215, 220, 225);
}

.performance-txt-2 {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.f-600 {
  font-weight: 600;
}

.browse-txt {
  display: block;
  font-size: 62px;
  font-weight: 800;
  line-height: 100%;
  letter-spacing: -0.02em;
  margin: 0px;
  padding: 0px;
}

.gXSiVi {
  border: 1px solid rgb(224, 226, 217);
  border-radius: 96px;
  color: rgb(103, 107, 95);
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.016em;
  line-height: 130%;
  margin-left: 8px;
  padding: 5px 10px;
  font-size: 16px;
}

.trust-text p {
  font-family: "Link Sans", Arial, sans-serif;
  font-size: clamp(16px, 2vmin, 20px);
  font-weight: 400 !important;
  letter-spacing: -0.02em;
  line-height: 1.5;
  margin-bottom: 16px;
  color: rgb(30, 35, 48);
  margin-top: 30px;
}

/********* ************/

@media (max-width: 600px) {
  .home-page .hero2 {
    background-color: rgb(233, 192, 233);
    /* height: 120vh; */
    display: flex;
    align-items: center;
    padding-top: 126px;
    padding-left: 12px;
    gap: 1%;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .home-page .hero3 {
    background-color: rgb(120, 0, 22);
    /* height: 110vh; */
    padding-top: 120px;
    gap: 0%;
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-left: 14px;
  }

  .hero-heading-3 {
    font-family: "Link Sans", "Arial Black", Arial, sans-serif;
    font-size: clamp(26px, 7vmin, 50px);
    font-weight: 800;
    letter-spacing: -0.02em;
    line-height: 1.05;
    text-align: left;
    color: rgb(233, 192, 233);
  }

  .home-page .hero1 {
    background-color: rgb(233, 192, 233);
    /* height: 105vh; */
    display: flex;
    align-items: center;
    padding-left: 14px;
    padding-top: 120px;
    gap: 1%;
    flex-direction: column;
    justify-content: center;
  }

  .login-btn {
    height: 55px;
    padding: 0;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .signup-btn {
    height: 55px;
    padding: 0;
    width: 130px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-box {
    padding-left: 12px;
    height: 72px;
  }

  .nav-box-wrap {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 28px;
  }

  .list-right {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .home-page .hero4 {
    background-color: rgb(243, 243, 241);
    height: 100vh;
    display: flex;
    align-items: center;
    padding-left: 14px;
    padding-top: 120px;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .hero-text-2 {
    margin-top: 14px;
  }

  .plans-text {
    line-height: 1;
    width: 83.2%;
  }

  .card {
    min-width: 300px;
    height: 400px;
  }

  .footer h1 {
    font-size: 24px;
  }

  .hero-form {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }

  .hero-form .claim-btn-inner {
    padding: 18px 14px 20px;
  }

  .footer .links-wrap {
    min-width: 0px;
    padding: 64px 24px;
  }

  .footer .bTRgKr {
    flex-direction: column;
  }

  .dubjts {
    font-size: 20px;
    font-weight: 700 !important;
  }

  .footer .list-right {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 15px;
  }

  .footer .kcskEf {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 100px;
    border-radius: 24px;
  }

  .login-main {
    padding: 3rem;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .recap {
    height: 10vh;
  }

  .recap.ss {
    height: 2vh;
  }

  .login-heading {
    font-size: 26px;
    letter-spacing: -2px;
  }

  .pricing-bar {
    min-width: 93.6%;
    /* display: grid; */
    /* grid-template-columns: 1fr auto; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .pricing-bar .pick-plan-heading {
    font-family: "Link Sans", "Arial Black", Arial, sans-serif;
    color: rgb(80, 34, 116);
    text-align: center;
    font-size: 45px;
  }

  .pricing-switch-txt {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    transform: translateX(-20px);
  }

  .home-page .pricing1 {
    height: 300vh;
  }

  .pricing-cards {
    grid-template-columns: repeat(1, 1fr);
  }

  .pricing-switch {
    transform: translateX(30px);
  }

  .search-engine {
    flex-direction: column;
  }

  .search-engine .filter,
  .search-engine .results {
    width: 100%;
  }

  .filter-links {
    flex-direction: row;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-30px);
  }

  .user-card {
    margin-bottom: 2rem;
    width: 100%;
  }

  .contact-form {
    padding: 30px 20px;
    background-color: #ffffff;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    width: 383px;
    height: 700px;
  }

  .contact-form-wrapper {
    font-family: "Link Sans", Arial, sans-serif;
    padding: 14px 0;
    margin-bottom: 100px;
  }

  .contact-text {
    width: 70%;
    text-align: center;
  }

  .profile-feed {
    padding-top: 22px;
    max-width: 97vw;
    padding-left: 10px;
  }

  .tree__section--footer {
    max-width: 320px;
    margin: auto;
  }

  .settings-feed {
    margin-left: 5vw;
    padding-top: 72px;
    min-width: 35vw;
  }

  .terms-page {
    padding-top: 200px;
    padding-left: 26px;
    padding-right: 0;
  }

  .home-page {
    overflow-x: hidden;
  }

  .home-page .hero1.mm {
    background-color: rgb(233, 192, 233);
    height: 105vh;
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-top: 80px;
    gap: 1%;
    flex-direction: column;
    justify-content: center;
  }

  .hero1-cg.ggg {
    max-width: 85vw;
    object-fit: contain;
    transform: translateY(8%);
  }

  .jMYFMD > div {
    border-radius: 12px;
  }

  .jMYFMD .img {
    border-radius: 12px;
  }

  .MIUTU {
    font-size: 20px;
    padding-left: 16px;
  }

  .foLnLr {
    padding-right: 16px;
    font-size: 12px;
  }

  .browse-txt {
    display: block;
    font-size: 32px;
    font-weight: 800;
  }
}

.catego {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.3);
  gap: 4px;
  padding: 5.5px 10px;
  border-radius: 9999px;
  outline: none !important;
  border: none;
}

.catego div {
  font-size: 15px;
}

.catego span {
  font-weight: 500;
}

.text-rs {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.32px;

  color: rgb(103 107 95 / 1);
  margin-bottom: 20px;
}

.link-bt:hover {
  transform: scale(1.02);
}

.link-box {
  background: #fff;
  box-shadow: 0 0 #0000, 0 0 #0000, inset 0px -1px 0px #e0e2d9;
  width: 90%;
  border-radius: 1.5rem;
  margin-bottom: 20px;
}

.p-lg {
  padding: 1.5rem;
}

.button-image {
  min-width: 100%;
  overflow: hidden;
  object-fit: cover;
}

.lom:hover {
  transform: scale(1.01);
  color: #000;
}

.nav-limst > div {
  font-family: "Link Sans", "Arial Black", Arial, sans-serif;
  font-size: clamp(26px, 7.5vmin, 68px);
  border-bottom: 1px solid pink;
  padding-bottom: 20px;
  padding-top: 10px;
}

.nav-limst > div:hover {
  transform: scale(1.03);
}

/* ComingSoon.css */
.coming-soon-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  font-family: "Arial", sans-serif;
  text-align: center;
}

.logo {
  width: 300px; /* Adjust the size as needed */
  margin-bottom: 20px;
}

.coming-heading {
  font-size: 24px;
  margin: 0;
}
.soon-text {
  color: #d12038;
}
